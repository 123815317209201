<template>
  <div class="container">
    <div class="header">
      <img
          class="header-menu"
          src="@/assets/icons/new_home/icon_menu.png"
          @click="onClickMenu"
      />

      <img v-if="!hasCacheBrandIcon" class="header-logo" src="@/assets/logo/logo_omni_head.png"/>
      <div v-else class="brand-logo">
        <van-image :src="brandInfo.brandCover" alt="" class="brand-logo-icon" fit="contain"/>
        <!--        <div>{{ brandInfo.brandName }}</div>-->
      </div>

      <img
          class="header-bluetooth"
          :src="
          isConnected
            ? require('@/assets/icons/icon_bluetooth_on.png')
            : require('@/assets/icons/icon_bluetooth_off.png')
        "
          @click="onClickFunction"
      />

      <div class="head-menu-show" v-show="showMenu">
        <img src="@/assets/icons/icon_triangle.png" class="triangle"/>
        <div class="menu-wrap">

          <div class="menu-item" @click="onClickMenuStore" v-if="false">
            <img src="@/assets/icons/icon_external_url.png"/>
            <span>iKrusher Store</span>
          </div>
<!--          <div class="menu-item" @click="onClickMyBrand" v-if="isBrandUser" >-->
<!--            <img src="@/assets/icons/icon_my_brand.png"/>-->
<!--            <span>My Brand</span>-->
<!--          </div>-->
          <div class="menu-item" @click="onClickMenuAccount" v-if="false">
            <img src="@/assets/icons/icon_account.png"/>
            <span>Account</span>
          </div>

          <div class="menu-item" @click="onClickClearCache" v-if="false">
            <img src="@/assets/icons/icon_clear_cache.png"/>
            <span>Clear Cache</span>
          </div>

          <div class="menu-item" @click="onClickSettings">
            <img src="@/assets/icons/icon_settings.png"/>
            <span>Settings</span>
          </div>

        </div>
      </div>

      <div class="head-function-show" v-show="showFunction">
        <img src="@/assets/icons/icon_triangle.png" class="triangle"/>
        <div class="menu-wrap">

          <div class="menu-item" @click="onClickConnectBlue">
            <img
                :src="
              isConnected
                ? require('@/assets/icons/new_home/icon_bluetooth_on.png')
                : require('@/assets/icons/new_home/icon_bluetooth_off.png')
            "
            />
            <span>{{ isConnected ? "Hub Connected" : "Search Hub" }}</span>
          </div>
          <div class="menu-item" @click="onClickGotoConnect">
            <img src="@/assets/icons/new_home/icon_nfc_normal.png"/>
            <span>NFC Connect</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mask-top" ref="maskTop" style="display:none;"></div>
    <div class="content" ref="content">
      <div class="content-setting">
        <van-dropdown-menu class="setting-menu">
          <!-- <van-dropdown-item
            v-model="curveModel"
            :options="option1"
            @change="onChangeModeDropdown"
          /> -->
          <!-- van-dropdown-item__option--active -->
          <van-dropdown-item :title="option1[curveModel].text" ref="dropdown">
            <div
                class="van-cell van-cell--clickable van-dropdown-item__option"
                :class="
                curveModel == index ? 'van-dropdown-item__option--active' : ''
              "
                v-for="(item, index) in option1"
                :key="index"
            >
              <div
                  class="van-cell__title"
                  @click="onClickSelectDropdownItem(index)"
              >
                <span>{{ item.text }}</span>
              </div>
              <img
                  v-if="modelList[index].isRecommend == 0"
                  @click="onClickDeleteDropdownItem(index)"
                  :src="
                  curveModel == index
                    ? require('@/assets/icons/new_home/icon_delete_mode_selected.png')
                    : require('@/assets/icons/new_home/icon_delete_mode.png')
                "
              />
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <div style="margin-left:auto;display: flex;align-items: center;justify-content: center">
          <img
              class="setting-edit"
              src="@/assets/icons/new_home/icon_edit.png"
              @click="onClickEdit"
          />
          <img
              v-if="false"
              class="setting-save"
              src="@/assets/icons/new_home/icon_save.png"
              @click="onClickSave"
          />
        </div>
      </div>
      <div class="content-title">{{ deviceType }}</div>
      <!-- 曲线1 -->
      <div
          id="voltageOne"
          ref="voltageOne"
          class="voltage-one"
          style="width:100%;height:calc(16vh)"
      ></div>
      <!-- 曲线2 -->
      <div
          id="voltageTwo"
          class="voltage-two"
          style="width:100%;height:calc(20vh)"
          @touchmove.prevent="onTouchmoveVoltage"
      >
        <div
            class="voltage-two-list"
            v-for="(value, index) in modelList[curveModel].diyVoltage"
            :key="index"
        >
          <van-icon
              name="plus"
              class="voltage-plus"
              size="15"
              :color="curveColor"
              @click="onPlusPower(index)"
          />
          <van-slider
              v-model="modelList[curveModel].diyVoltage[index]"
              vertical
              :min="-limitMaxVoltage"
              :max="-limitMinVoltage"
              :step="100"
              bar-height="4px"
              active-color="#E6E7E8"
              :inactive-color="curveColor"
              @input="onChangeVoltageCurve(index)"
          >
            <template #button>
              <!-- <img
                class="slider-button"
                src="@/assets/icons/icon_slider_button.png"
              /> -->
              <div
                  class="slider-button"
                  :style="`border: 2px solid ${curveColor}`"
              ></div>
            </template>
          </van-slider>
          <van-icon
              name="minus"
              size="15"
              class="voltage-reduce"
              :color="curveColor"
              @click="onReducePower(index)"
          />
        </div>
      </div>
      <div class="voltage-three">
        <div class="voltage-number" v-for="index of 8" :key="index">
          {{ index }}s
        </div>
      </div>
      <div class="coli-setting">
        <div class="coil-resistor">
          Coil Resistor：
          <div class="resistance-value">
            {{ resistance }}

            <!--<input-->
            <!--:disabled="true"-->
            <!--type="number"-->
            <!--max="2"-->
            <!--min="0.1"-->
            <!--v-model.trim="coilResistorValue"-->
            <!--pattern="[0-9]*\.?[0-9]{0,1}"-->
            <!--oninput="if(value.length>3)value=value.slice(0,3);if(value>2)value=2;if(value<0)value=0;this.value=this.value.replace(/\D*(\d*)(\.?)(\d{0,1})\d*/,'$1$2$3')"-->
            <!--/>-->
          </div>
          <img
              src="@/assets/icons/new_home/icon_refresh.png"
              class="refresh-icon"
              @click="loadDeviceSetting(true)"
          />
          <!--<van-dropdown-menu>-->
          <!--<van-dropdown-item-->
          <!--v-model="coilResistorValue"-->
          <!--:options="coilResistorList"-->
          <!--@open="onROpen"-->
          <!--@change="onChangeRDropdown"-->
          <!--/>-->
          <!--</van-dropdown-menu>-->
        </div>
        <div class="max-power">
          Max Power：
          <van-dropdown-menu>
            <van-dropdown-item
                v-model="maxPowerValue"
                :options="maxPowerList"
                @change="onChangeRDropdown"
            />
          </van-dropdown-menu>
        </div>
      </div>

      <div class="device-level" v-if="isD019">
        Curve：
        <van-dropdown-menu>
          <van-dropdown-item ref="item">
            <template #title>
              <div
                  class="drop-item-level"
                  :style="{ background: currentDeviceLevelItem.text }"
              ></div>
            </template>
            <template>
              <div
                  class="drop-item-level__wrap"
                  v-for="(item, index) in deviceLevelList"
                  :key="index"
                  @click="onLevelDropdown(item)"
                  :style="{
                  background: index === currentDeviceLevel ? '#86a1a9' : ''
                }"
              >
                <div
                    class="drop-item-level__content"
                    :style="{ background: item.text }"
                ></div>
              </div>
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <div class="custom-curve">
        <van-button
            v-for="(mode, index) in topModeList"
            :key="mode.id"
            class="custom-item"
            @click="onRecommendModeClick(mode)"
            :class="
            mode.id === modelList[curveModel].id
              ? 'recommended-button-active'
              : ''
          "
            v-press="
            () => {
              handleClickLong(topModeList[index]);
            }
          "
        >
          {{ mode.modeName }}
        </van-button>
      </div>
      <div class="recommend-curve" v-show="bottomModeList.length > 0">
        <van-button
            v-for="(mode, index) in bottomModeList"
            :key="mode.id"
            class="recommend-item"
            @click="onRecommendModeClick(mode)"
            v-press="
            () => {
              handleClickLong(bottomModeList[index]);
            }
          "
            :class="
            mode.id === modelList[curveModel].id
              ? 'recommended-button-active'
              : ''
          "
        >
          {{ mode.modeName }}
        </van-button>
      </div>
      <div class="control" v-if="false">
        <div class="control-left">
          <div class="title">
            Preheat
            <van-switch
                class="preheat-switch"
                v-model="modelList[curveModel].isSupportPreheat"
                active-color="#b9c4c6"
                inactive-color="#a7a9ac"
                @change="onPreheatChange"
                size="16"
            />
          </div>
          <div class="subtitle">Time</div>
          <div
              class="preheat-item"
              :style="
              modelList[curveModel].isSupportPreheat
                ? 'background:#D8D8D8;'
                : 'background:#E6E7E8;'
            "
          >
            <img
                src="@/assets/icons/new_home/icon_reduce.png"
                v-if="modelList[curveModel].isSupportPreheat"
                @touchstart="onClickTimesLongClickStart('-')"
                @touchend="onClickTimesLongClickEnd('-')"
            />
            <img v-else src="@/assets/icons/new_home/icon_reduce_disable.png"/>
            <span
            >{{
                (modelList[curveModel].preheatTime / 1000).toFixed(1)
              }}&nbsp;s</span
            >
            <img
                v-if="modelList[curveModel].isSupportPreheat"
                src="@/assets/icons/new_home/icon_add.png"
                @touchstart="onClickTimesLongClickStart('+')"
                @touchend="onClickTimesLongClickEnd('+')"
            />
            <img v-else src="@/assets/icons/new_home/icon_add_disable.png"/>
          </div>
          <div class="subtitle">Power</div>
          <div
              class="preheat-item"
              :style="
              modelList[curveModel].isSupportPreheat
                ? 'background:#D8D8D8;'
                : 'background:#E6E7E8;'
            "
          >
            <img
                :src="
                modelList[curveModel].isSupportPreheat
                  ? require('@/assets/icons/new_home/icon_reduce.png')
                  : require('@/assets/icons/new_home/icon_reduce_disable.png')
              "
                @touchstart="onClickVoltageLongClickStart('-')"
                @touchend="onClickVoltageLongClickEnd('-')"
            />
            <span
            >{{ modelList[curveModel].preheatPower.toFixed(2) }}&nbsp;W</span
            >
            <img
                v-if="modelList[curveModel].isSupportPreheat"
                src="@/assets/icons/new_home/icon_add.png"
                @touchstart="onClickVoltageLongClickStart('+')"
                @touchend="onClickVoltageLongClickEnd('+')"
            />
            <img v-else src="@/assets/icons/new_home/icon_add_disable.png"/>
          </div>
        </div>
        <div class="control-right">
          <div class="title">NFC Settings</div>
          <van-switch
              class="nfc-switch"
              v-model="modelList[curveModel].isSupportNfc"
              active-color="#b9c4c6"
              inactive-color="#a7a9ac"
              size="16"
              @change="onNfcChange"
          />
          <div class="msg">
            Only works with device that supports NFC function
          </div>
        </div>
      </div>
    </div>
    <div class="mask-bottom" ref="maskBottom" style="display:block;"></div>
    <div class="footer" ref="footer">
      <div class="button-device" @click="onShowDeviceSetting">
        <img src="@/assets/icons/new_home/icon_device.png"/>
        <div class="text">Read Device</div>
      </div>
      <van-button
          class="button-upload"
          type="primary"
          @click="onClickUpload"
      >
        Sync to Hub
      </van-button>
      <div class="button-history" @click="onClickHistory">
        <img src="@/assets/icons/new_home/icon_history.png"/>
        <div class="text">History</div>
      </div>
    </div>

    <!-- 连接蓝牙弹窗Popup -->
    <van-popup
        class="bluetooth-popup"
        v-model="showBluetoothPopup"
        round
        position="bottom"
        :close-on-click-overlay="false"
    >
      <div class="popup-header">
        Devices：
        <span @click="onClickCloseBluetoothPopup">Close</span>
      </div>
      <div class="popup-content" v-if="deviceList.length > 0">
        <div class="device-title">
          OMNI iKhub
        </div>
        <div class="device-mac">
          {{ getDeviceMacAddress(deviceList[currentDeviceIndex]) }}
        </div>
        <div
            class="device-list"
            @touchstart="touchstart"
            @touchmove="touchmove"
        >
          <img
              class="device-left"
              src="@/assets/icons/new_home/icon_left.png"
              @click="onClickSwitchLeft"
              :style="
              deviceList.length == 1 || currentDeviceIndex == 0
                ? 'visibility: hidden;'
                : 'visibility:initial;'
            "
          />
          <div class="device-hub-list" ref="deviceList">
            <!-- <img
              class="hub-img"
              src="@/assets/icons/new_home/icon_omni_hub.png"
            /> -->
            <van-swipe
                ref="deviceSwipe"
                class="my-swipe"
                :autoplay="0"
                :duration="600"
                indicator-color="white"
                :loop="false"
                :show-indicators="false"
                :touchable="false"
            >
              <van-swipe-item v-for="index of deviceList.length" :key="index">
                <img
                    class="hub-img"
                    src="@/assets/icons/new_home/icon_omni_hub.png"
                />
              </van-swipe-item>
            </van-swipe>
          </div>
          <img
              class="device-right"
              src="@/assets/icons/new_home/icon_right.png"
              @click="onClickSwitchRight"
              :style="
              deviceList.length == 1 ||
              currentDeviceIndex + 1 == deviceList.length
                ? 'visibility: hidden;'
                : 'visibility:initial;'
            "
          />
        </div>
        <van-button
            class="button-connect"
            @click="onClickConnectDevice"
            :disabled="connectState !== 1"
        >
          {{ isConnected ? "Disconnect" : "Connect" }}
        </van-button>
      </div>
      <div class="popup-content" v-else>
        <img class="no-device" src="@/assets/icons/new_home/no_device.png"/>
        <span class="no-device-msg">No device found</span>
      </div>
    </van-popup>
    <!-- 断开蓝牙弹窗 Disconnect -->
    <van-popup
        class="save-popup"
        v-model="showDisconnectPopup"
        round
        :style="{ width: '88%' }"
        :close-on-click-overlay="false"
    >
      <div class="save-header">Tips</div>
      <div class="save-content">
        <div class="save-content-msg">
          Are you sure to disconnect?
        </div>
        <div class="save-footer">
          <button class="save-footer-cancel" @click="onClickDisconnectCancel">
            Cancel
          </button>
          <button class="save-footer-sure" @click="onClickDisconnectSure">
            Sure
          </button>
        </div>
      </div>
    </van-popup>
    <!-- Device Popup -->
    <van-popup
        class="settings-popup"
        close-icon="close"
        round
        v-model="showSettingsPopup"
        :close-on-click-overlay="true"
        close-icon-position="top-right"
    >
      <CurrentSetting
          v-if="showSettingsPopup"
          :r-value="currentSetR"
          :max-value="parseFloat(this.maxPowerValue)"
          :showSettingsPopup="showSettingsPopup"
          @onApplyToHome="onApplyToHome"
          v-on:close="onCloseDeviceSetting"
      />
    </van-popup>
    <!-- Edit Popup -->
    <van-popup
        class="save-popup"
        v-model="showEditPopup"
        round
        :style="{ width: '88%' }"
        :close-on-click-overlay="false"
    >
      <div class="save-header">Rename</div>
      <div class="edit-content">
        <div class="edit-content-msg">
          Please input the mode name
        </div>
        <input
            class="input-rename"
            type="text"
            v-model.trim="editName"
            placeholder="Please enter name"
            oninput="if(value.length>=25)value=value.slice(0,25)"
        />

        <div class="memo-content-msg">
          Memo
        </div>
        <textarea
            class="input-memo"
            v-model.trim="memoName"
            placeholder="Enter memo content"
            rows="10"
        />

        <div class="save-footer">
          <button
              class="save-footer-cancel"
              @click="
              () => {
                this.showEditPopup = false;
                this.editName = '';
                this.memoName = '';
              }
            "
          >
            Cancel
          </button>
          <button class="save-footer-sure" @click="onClickEditConfirm">
            Confirm
          </button>
        </div>
      </div>
    </van-popup>
    <!-- Save Popup -->
    <van-popup
        class="save-popup"
        v-model="showSavePopup"
        round
        :style="{ width: '88%' }"
        :close-on-click-overlay="false"
    >
      <div class="save-header">Power Curve</div>
      <div class="edit-content">
        <div class="edit-content-msg">
          Please input the mode name
        </div>
        <input
            class="input-rename"
            type="text"
            v-model.trim="saveName"
            placeholder="Please enter name"
            oninput="if(value.length>=25)value=value.slice(0,25)"
        />

        <div class="memo-content-msg">
          Memo
        </div>
        <textarea
            class="input-memo"
            v-model.trim="memoName"
            placeholder="Enter memo content"
            rows="10"
        />
        <div class="save-footer">
          <button
              class="save-footer-cancel"
              @click="
              () => {
                this.showSavePopup = false;
                this.saveName = '';
                this.memoName = '';
              }
            "
          >
            Cancel
          </button>
          <button class="save-footer-sure" @click="onClickSaveConfirm">
            Confirm
          </button>
        </div>
      </div>
    </van-popup>
    <!-- Rename Popup-->
    <van-popup
        class="rename-popup"
        round
        closeable
        close-icon="close"
        v-model="showRenamePopup"
        :close-on-click-overlay="false"
        close-icon-position="top-right"
    >
      <div class="popup-header">Rename</div>
      <div class="popup-content">
        <div class="content-title">Please input the mode name</div>
        <input
            ref="rename"
            class="popup-rename"
            type="text"
            v-model="rename"
            placeholder="Enter new name"
            maxlength="10"
        />

        <div class="memo-content-msg">
          Memo
        </div>
        <textarea
            class="input-memo"
            v-model.trim="memoName"
            placeholder="Enter memo content"
            rows="10"
        />
        <div class="popup-footer">
          <button class="footer-cancel" @click="onClickRenameCancel">
            Default
          </button>
          <button class="footer-confirm" @click="onClickRenameConfirm">
            Confirm
          </button>
        </div>
      </div>
    </van-popup>
    <!-- Delete Popup-->
    <van-popup
        class="rename-popup"
        close-icon="close"
        round
        v-model="showDeleteModePopup"
        :close-on-click-overlay="false"
        close-icon-position="top-right"
    >
      <div class="popup-header">Tips</div>
      <div class="popup-content">
        <div class="content-title">Delete curve mode</div>
      </div>
      <div class="popup-footer">
        <button class="footer-cancel" @click="onClickDeleteModeCancel">
          Cancel
        </button>
        <button class="footer-confirm" @click="onClickDeleteModeConfirm">
          Confirm
        </button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { d019ChartConfig } from "@/config/echarts-data";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import device from "@/api/device";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import { TOKEN } from "@/config/http-json";
import CurrentSetting from "@/components/CurrentSetting";
import {
  calcMaxPower,
  calcPower,
  calcVoltageCeilInt,
  calcVoltageFloorInt,
  calcVoltageInt,
  handleMaxPowerVoltage,
  toFloat
} from "../util/utils";
import log from "@/util/log";
import press from "@/util/press";
import { Dialog } from "vant";
import bus from "@/util/EventBus";

const graphic = require("echarts/lib/util/graphic");
export default {
  name: "NewHome",
  directives: {
    press
  },
  components: {
    CurrentSetting
  },
  data() {
    return {
      showMenu: false,
      showFunction: false,
      curveModel: 0,
      modelList: [{ power: [], preheatPower: 0.1, preheatTime: 3 }],
      option1: [{ text: "Customize", value: 0 }],
      prevCoilResistorValue: "",
      // coilResistorValue: "1.2",
      coilResistorList: [
        { text: "1.0 ohm", value: "1.0" },
        { text: "1.1 ohm", value: "1.1" },
        { text: "1.2 ohm", value: "1.2" },
        { text: "1.3 ohm", value: "1.3" },
        { text: "1.4 ohm", value: "1.4" },
        { text: "1.5 ohm", value: "1.5" },
        { text: "1.6 ohm", value: "1.6" }
      ],
      maxPowerValue: "10",
      maxPowerList: [
        { text: "12 W", value: "12" },
        { text: "11 W", value: "11" },
        { text: "10 W", value: "10" },
        { text: "9 W", value: "9" },
        { text: "8 W", value: "8" },
        { text: "7 W", value: "7" },
        { text: "6 W", value: "6" },
        { text: "5 W", value: "5" }
      ],
      currentDeviceLevel: 0,
      currentDeviceLevelItem: { text: "yellow", value: 0 },
      deviceLevelList: [
        { text: "yellow", value: 0 },
        { text: "white", value: 1 }
      ],
      curveChart: null,
      showBluetoothPopup: false,
      showDisconnectPopup: false,
      currentDeviceIndex: 0,

      preheatTimeTimeoutId: -1,
      preheatTimeIntervalId: -1,
      preheatTimeLongClickIsRun: false,

      preheatVoltageTimeoutId: -1,
      preheatVoltageIntervalId: -1,
      preheatVoltageLongClickIsRun: false,

      showSettingsPopup: false,
      showAccountPopup: false,
      accountInfo: {
        username: ""
      },
      showEditPopup: false,
      showSavePopup: false,
      showRenamePopup: false,
      showDeleteModePopup: false,
      editName: "",
      saveName: "",
      memoName: "",
      connectTimeoutId: -1,
      cancelSearchTimeoutId: -1,

      currentSetR: 1.2,
      renameMode: null,
      waitDeleteMode: null,
      rename: "",
      limitMaxVoltage: 3500,
      limitMinVoltage: 350,
      queryDeviceTimeoutId: -1,
      curveLevelColor: ["green", "rgba(255, 140, 0, 1)", "red"],
      curveLevelColorLinearGradient: [
        ["rgba(0, 255, 0, 1)", "rgba(0, 255, 0, 0.2)"],
        ["rgba(255, 140, 0, 1)", "rgba(255, 140, 0, 0.2)"],
        ["rgba(255, 0, 0, 1)", "rgba(255, 0, 0, 0.2)"]
      ],
      curveColor: "#86A1A9",
      curveColorLinearGradient: [
        "rgba(192, 222, 226, 1)",
        "rgba(246, 250, 250, 1)"
      ],
      retryQueryTimes: 3,
      isSupportCache: window.hasOwnProperty("clearAppCache")
    };
  },
  created() {
    if (this.$route.params.writerSetting) {
      this.onApplyToHome(this.$route.params.writerSetting)
    }
  },
  activated() {
  },
  mounted() {
    if (this.$refs.voltageOne) {
      this.curveChart = this.$echarts.init(this.$refs.voltageOne);
      window.addEventListener("resize", this.onWindowSizeChanged);
    }

    this.$refs.content.addEventListener("scroll", this.handleScroll);

    let account = JSON.parse(window.localStorage.getItem("omni-user"));
    if (account) {
      this.accountInfo = account;
      this._setLoginInfo(account);
    }

    this.modelList = [this.createCustomizeMode()];
    this.refreshVoltageCurve();
    this.loadData();
    bus.$on('resetAppBt', this.loadData);
  },
  destroyed() {
    bus.$off('resetAppBt',this.loadData)
  },
  watch: {
    deviceList(value) {
      log(value);
    },
    deviceId(value) {
      if (value) {
        if (value.type === 5) {
          //电池修改颜色
          const level = ((value.productManufacture >> 4) & 0x0f) - 1;
          log(`当前电池杆等级:${level}`);
          if (level < 3) {
            this.curveColor = this.curveLevelColor[level];
            this.curveColorLinearGradient = this.curveLevelColorLinearGradient[
                level
                ];
            this.currentDeviceLevel = level;
            this.refreshVoltageCurve();
            return;
          }
        }
      } //为空了
      if (this.isD019) {
        const level = this.level;
        log(`当前d019的档位:${level}`);
        if (level < 2) {
          this.currentDeviceLevelItem = Object.assign({},this.deviceLevelList[level]);
          this.currentDeviceLevel = this.level;
          console.log(JSON.stringify(this.currentDeviceLevelItem))
        }
      }
      this.refreshVoltageCurve();
    },
    async isConnected(value) {
      if (value) {
        clearTimeout(this.connectTimeoutId);
        this.$toast({
          type: "success",
          message: "Device Connected",
          duration: 1500,
          forbidClick: true
        });
        await this.loadDeviceSetting(false);
        this.loadData();
        this.cancelSearchTimeoutId = setTimeout(() => {
          this.showBluetoothPopup = false;
          bluetoothRepository.cancelSearch();
        }, 2000);
      } else {
        clearTimeout(this.cancelSearchTimeoutId);
        this.loadData();

        //this.resetData(); //断开连接清除推荐曲线
      }
    },
    showMenu(newVal, oldVal) {
      if (newVal) {
        document.addEventListener("click", this.click_allFunction_side, true);
        document.addEventListener("scroll", this.click_allFunction_side, true);
      } else {
        if (document.removeEventListener) {
          document.removeEventListener(
              "click",
              this.click_allFunction_side,
              true
          );
          document.removeEventListener(
              "scroll",
              this.click_allFunction_side,
              true
          );
        } else if (document.detachEvent) {
          document.detachEvent("click", this.click_allFunction_side, true);
          document.detachEvent("scroll", this.click_allFunction_side, true);
        }
      }
    },
    showFunction(newVal, oldVal) {
      if (newVal) {
        document.addEventListener("click", this.click_allFunction_side, true);
        document.addEventListener("scroll", this.click_allFunction_side, true);
      } else {
        if (document.removeEventListener) {
          document.removeEventListener(
              "click",
              this.click_allFunction_side,
              true
          );
          document.removeEventListener(
              "scroll",
              this.click_allFunction_side,
              true
          );
        } else if (document.detachEvent) {
          document.detachEvent("click", this.click_allFunction_side, true);
          document.detachEvent("scroll", this.click_allFunction_side, true);
        }
      }
    },
    coilResistorValue(newValue) {
      if (parseFloat(newValue).toString() === "NaN") {
        return;
      }
      log(`change = ${newValue}`);
      this.onChangeRDropdown(newValue);
    }
  },
  computed: {
    ...mapState("bluetooth", [
      "isConnected",
      "macAddress",
      "cartridgeFlag",
      "deviceVersion",
      "deviceList",
      "writerSetting",
      "connectState",
      "deviceId",
      "insertDeviceName",
      "level"
    ]),

    ...mapState(["brandInfo"]),

    isBrandUser() {
      const user = this.getLoginInfo()
      return (user && user.supplierType === 0)
    },

    hasCacheBrandIcon() {
      if (this.brandInfo && this.brandInfo.supplierId !== 749 && this.brandInfo.brandCover) {
        return true
      }
      return false
    },

    deviceType() {
      if (this.isConnected) {
        if (this.deviceId) {
          switch (this.deviceId.type) {
            case 1:
              return "Smart Disposable";
            case 3:
              break;
            case 5:
              return "510 Smart Battery";
            default:
              return "Smart Pod System";
          }
        }
      }
      return "Power Curve";
    },

    isSupportLevel() {
      if (this.isConnected) {
        if (this.deviceId) {
          const insertDeviceName = this.insertDeviceName;
          if (
              insertDeviceName === "510 Pen" ||
              insertDeviceName === "Lipstick" ||
              insertDeviceName === "PORTO" ||
              insertDeviceName === "D022" ||
              insertDeviceName === "D023"
          ) {
            return true;
          }
        }
      }
      return false;
    },

    isD019() {
      if (this.isConnected) {
        if (this.deviceId) {
          const insertDeviceName = this.insertDeviceName;
          if (
              insertDeviceName === "PORTO" ||
              insertDeviceName === "D022" ||
              insertDeviceName === "D023"
          ) {
            return true;
          }
        }
      }
      return false;
    },
    topModeList() {
      const recommendList = this.modelList.filter(
          item => item.isRecommend || false
      );
      if (recommendList.length < 5) {
        return recommendList;
      }
      return recommendList.slice(0, 4);
    },
    bottomModeList() {
      const recommendList = this.modelList.filter(
          item => item.isRecommend || false
      );
      if (recommendList.length > 4) {
        return recommendList.slice(4, recommendList.length >= 6 ? 6 : 5);
      }
      return [];
    },
    resistance() {
      if (this.isConnected) {
        if (this.deviceId) {
          const r = this.deviceId.atomizedR;
          if (this.getInsertDeviceName() == "NORD") {
            return "1.2ohm";
          }
          return `${parseFloat((r / 10).toFixed(1))}ohm`;
        }
        //没有插入设备
        return "1.2ohm";
      }
      return "No value";
    },

    coilResistorValue() {
      if (this.deviceId) {
        const r = this.deviceId.atomizedR;
        if (this.getInsertDeviceName() == "NORD") {
          return "1.2";
        }
        return `${parseFloat((r / 10).toFixed(1))}`;
      }
      return "1.2";
    }
  },
  methods: {
    ...mapMutations(["setKeepAlive"]),
    ...mapActions(["_setLoginInfo"]),
    ...mapGetters(["getLoginInfo", "getRecommendAliasNameList"]),
    ...mapGetters("bluetooth", ["getInsertDeviceName"]),
    onApplyToHome(writerSetting) {


      this.showSettingsPopup = false
      this.curveModel = 0
      this.copyWriterSetting( writerSetting,this.modelList[0]);
      this.copyOriginWriterSetting( writerSetting,this.modelList[0]);

      window.localStorage.setItem(
          `current_select_mode_${this.getLoginInfo().id}`,
          this.modelList[this.curveModel].id
      );
      this.onChangeRDropdown(0)
    },
    onClickSelectDropdownItem(index) {
      this.curveModel = index;
      //保存当前设备的选择模式id
      if (this.isConnected) {
        window.localStorage.setItem(
            `current_select_mode_${this.getLoginInfo().id}_${this.macAddress}`,
            this.modelList[this.curveModel].id
        );
      } else {
        window.localStorage.setItem(
            `current_select_mode_${this.getLoginInfo().id}`,
            this.modelList[this.curveModel].id
        );
      }
      this.onChangeRDropdown(index);
      this.$refs.dropdown.toggle();
    },
    onClickDeleteDropdownItem(index) {
      // this.$refs.dropdown.toggle();
      //开始处理
      this.waitDeleteMode = this.option1[index];
      this.showDeleteModePopup = true;
      this.$refs.dropdown.toggle();
    },
    getDeviceMacAddress(device) {
      if (navigator.bluetooth2) {
        return device.id;
      } else {
        const deviceName = device.name.replace("HB", "").trim();
        let macAddressList = [];
        for (let i = 0; i < deviceName.length / 2; i++) {
          macAddressList.push(deviceName.slice(i * 2, i * 2 + 2));
        }
        return macAddressList.join(":");
      }
    },
    copyWriterSetting(origin, dest) {
      dest.diyVoltage = origin.diyVoltage.map(item =>
          handleMaxPowerVoltage(
              Math.floor(item / 100) * 100,
              this.limitMaxVoltage,
              this.limitMinVoltage
          )
      );
      dest.preheatVoltage = Math.abs(
          handleMaxPowerVoltage(
              Math.floor(Math.abs(origin.preheatVoltage) / 100) * 100,
              this.limitMaxVoltage,
              this.limitMinVoltage
          )
      );
      dest.preheatTime = origin.preheatTime;
      dest.isSupportNfc = origin.isSupportNfc;
      dest.isSupportPreheat = origin.isSupportPreheat;
      dest.remark = origin.remark;
    },

    copyOriginWriterSetting(origin, dest) {
      dest.originDiyVoltage = [].concat(
          origin.diyVoltage.map(item =>
              handleMaxPowerVoltage(
                  Math.floor(item / 100) * 100,
                  this.limitMaxVoltage,
                  this.limitMinVoltage
              )
          )
      ); //复制一份出来，保存的时候更新这一份数据
      dest.originPreheatVoltage = Math.abs(
          handleMaxPowerVoltage(
              Math.floor(origin.preheatVoltage / 100) * 100,
              this.limitMaxVoltage,
              this.limitMinVoltage
          )
      ); //复制一份出来，保存的时候更新这一份数据
      dest.originPreheatTime = origin.preheatTime;
      dest.originIsSupportNfc = origin.isSupportNfc;
      dest.originIsSupportPreheat = origin.isSupportPreheat;
    },

    async loadData(isFromSave = false,resolve,reject) {
      try {
        console.log('load data')
        await this.$store.dispatch("fetchRecommendListAliasName","bluetooth"); //请求本地的系统推荐曲线数据
        const data = await device.deviceVoltages({
          modelIdentification: this.isConnected ? "HB" : "HB"
          // modelIdentification: "HB"
        }); //获取服务器的曲线列表数据
        resolve && resolve()
        this.option1 = [this.option1[0]];
        if (isFromSave) {
          this.modelList = [this.modelList[0]];
        } else {
          this.modelList = [this.createCustomizeMode()];
        }
        const recommendAliasNameList = this.getRecommendAliasNameList(); //获取本地的系统推荐数据
        this.modelList = this.modelList.concat(
            //服务器曲线数据组建自己的数据
            data.map((item, index) => {
              let aliasNameItemIndex = -1;
              if (recommendAliasNameList) {
                //通过id找到本地的索引
                aliasNameItemIndex = recommendAliasNameList.findIndex(
                    aliasItem => aliasItem.id === item.id
                );
              }

              const r = toFloat(this.coilResistorValue, 1.2);
              let maxPower = toFloat(this.maxPowerValue, 10);
              //从最大功率算回最大电压
              this.limitMaxVoltage = calcVoltageFloorInt(maxPower, r);
              this.limitMinVoltage = calcVoltageCeilInt(0.1, r);
              maxPower = calcMaxPower(this.limitMaxVoltage, r);

              const setting = new WriterSetting();
              setting.id = item.id;
              if (aliasNameItemIndex === -1) {
                //本地的没有系统推荐的数据
                setting.diyVoltage = item.heatingVoltage
                    .split(",")
                    .map(item =>
                        handleMaxPowerVoltage(
                            Math.floor(item / 100) * 100,
                            this.limitMaxVoltage,
                            this.limitMinVoltage
                        )
                    ); //矫正数据
                setting.isSupportNfc = item.nfcSettings === 1;
                setting.isSupportPreheat = item.preheatSetting === 1;
                setting.preheatTime = item.preheatTime;
                setting.preheatVoltage = Math.abs(
                    handleMaxPowerVoltage(
                        Math.floor(Math.abs(item.preheatVoltage) / 100) * 100,
                        this.limitMaxVoltage,
                        this.limitMinVoltage
                    )
                );
                setting.remark = item.heatingRemarks;
                setting.modeName = item.modeName;
                setting.touchSensitivity = item.touchSensitivity;
              } else {
                //读取系统缓存的数据
                const localSaveRecommendMode =
                    recommendAliasNameList[aliasNameItemIndex];
                this.copyWriterSetting(localSaveRecommendMode, setting); //从本地保存的数据恢复到加载的数据中
                setting.modeName = localSaveRecommendMode.modeName;
                setting.touchSensitivity =
                    localSaveRecommendMode.touchSensitivity;
              }

              this.copyOriginWriterSetting(setting, setting);

              setting.power = setting.diyVoltage.map(
                  item => calcPower(item, r, maxPower) * -1
              );
              setting.isRecommend = item.recommendStatus === 0;
              setting.preheatPower = calcPower(
                  setting.preheatVoltage,
                  r,
                  maxPower
              );

              //更新本地推荐曲线的服务器数据部分数据

              if (aliasNameItemIndex !== -1) {
                const localSaveRecommendMode =
                    recommendAliasNameList[aliasNameItemIndex];
                localSaveRecommendMode.serverData = new WriterSetting();

                localSaveRecommendMode.serverData.diyVoltage = item.heatingVoltage
                    .split(",")
                    .map(item =>
                        handleMaxPowerVoltage(
                            Math.floor(item / 100) * 100,
                            this.limitMaxVoltage,
                            this.limitMinVoltage
                        )
                    ); //矫正数据
                localSaveRecommendMode.serverData.modeName = item.modeName;
                localSaveRecommendMode.serverData.isSupportNfc =
                    item.nfcSettings === 1;
                localSaveRecommendMode.serverData.isSupportPreheat =
                    item.preheatSetting === 1;
                localSaveRecommendMode.serverData.preheatTime = item.preheatTime;
                localSaveRecommendMode.serverData.preheatVoltage = Math.abs(
                    handleMaxPowerVoltage(
                        Math.floor(Math.abs(item.preheatVoltage) / 100) * 100,
                        this.limitMaxVoltage,
                        this.limitMinVoltage
                    )
                );
                localSaveRecommendMode.serverData.remark = item.heatingRemarks;
                localSaveRecommendMode.serverData.power = localSaveRecommendMode.serverData.diyVoltage.map(
                    item => calcPower(item, r, maxPower) * -1
                );
                localSaveRecommendMode.serverData.isRecommend =
                    item.recommendStatus === 0;
                localSaveRecommendMode.serverData.preheatPower = calcPower(
                    setting.preheatVoltage,
                    r,
                    maxPower
                );
                window.localStorage.setItem(
                    `alias_recommend_name_list_${this.getLoginInfo().id}_${
                        this.macAddress
                    }`,
                    JSON.stringify(recommendAliasNameList)
                );

                this.$store.commit(
                    "setRecommendAliasNameList",
                    recommendAliasNameList
                );

                setting.serverData = new WriterSetting();
                Object.assign(setting.serverData, localSaveRecommendMode);
              } else {
                setting.serverData = new WriterSetting();

                setting.serverData.diyVoltage = item.heatingVoltage
                    .split(",")
                    .map(item =>
                        handleMaxPowerVoltage(
                            Math.floor(item / 100) * 100,
                            this.limitMaxVoltage,
                            this.limitMinVoltage
                        )
                    ); //矫正数据
                setting.serverData.modeName = item.modeName;
                setting.serverData.isSupportNfc = item.nfcSettings === 1;
                setting.serverData.isSupportPreheat = item.preheatSetting === 1;
                setting.serverData.preheatTime = item.preheatTime;
                setting.serverData.preheatVoltage = Math.abs(
                    handleMaxPowerVoltage(
                        Math.floor(Math.abs(item.preheatVoltage) / 100) * 100,
                        this.limitMaxVoltage,
                        this.limitMinVoltage
                    )
                );
                setting.serverData.remark = item.heatingRemarks;
                setting.serverData.power = setting.serverData.diyVoltage.map(
                    item => calcPower(item, r, maxPower) * -1
                );
                setting.serverData.isRecommend = item.recommendStatus === 0;
                setting.serverData.preheatPower = calcPower(
                    setting.preheatVoltage,
                    r,
                    maxPower
                );
              }

              this.option1.push({
                text: setting.modeName,
                value: index + 1,
                id: setting.id
              });
              return setting;
            })
        );

        let prevSelectModeId;
        //之前选择的id
        if (this.isConnected) {
          prevSelectModeId = window.localStorage.getItem(
              `current_select_mode_${this.getLoginInfo().id}_${this.macAddress}`,
              this.modelList[this.curveModel].id
          );
        } else {
          prevSelectModeId = window.localStorage.getItem(
              `current_select_mode_${this.getLoginInfo().id}`
          );
        }
        const targetIndex = this.modelList.findIndex(
            item => item.id == prevSelectModeId
        ); //找到对应的索引
        this.curveModel = targetIndex === -1 ? 0 : targetIndex;
        this.refreshVoltageCurve();
      } catch (e) {
        this.modelList = [this.createCustomizeMode()];
        this.refreshVoltageCurve();
        reject && reject()
      }


      if (this.$route.params.writerSetting) {
        this.$nextTick(()=> {
          this.onApplyToHome(this.$route.params.writerSetting)
          this.$route.params.writerSetting = null
        })
      }
    },
    copyValueToCustomizer() {
      this.modelList[0].id = 0;
      this.modelList[0].diyVoltage = [].concat(
          this.modelList[this.curveModel].diyVoltage
      );
      this.modelList[0].isSupportNfc = this.modelList[
          this.curveModel
          ].isSupportNfc;
      this.modelList[0].isSupportPreheat = this.modelList[
          this.curveModel
          ].isSupportPreheat;
      this.modelList[0].preheatTime = this.modelList[
          this.curveModel
          ].preheatTime;
      this.modelList[0].preheatVoltage = this.modelList[
          this.curveModel
          ].preheatVoltage;
      this.modelList[0].power = [].concat(
          this.modelList[this.curveModel].power
      );
      this.modelList[0].preheatPower = this.modelList[
          this.curveModel
          ].preheatPower;
    },
    resetData() {
      this.modelList = [this.modelList[0]];
      this.option1 = [{ text: "Customize", value: 0 }];
    },
    createCustomizeMode() {
      const setting = new WriterSetting();
      const r = parseFloat(this.coilResistorValue);
      const maxPower = parseFloat(this.maxPowerValue);
      setting.id = 0;
      setting.modeName = "Customize";
      setting.originDiyVoltage = [].concat(setting.diyVoltage);
      setting.power = setting.diyVoltage.map(
          item => calcPower(item, r, maxPower) * -1
      );
      setting.preheatPower = calcPower(setting.preheatVoltage, r, maxPower);
      return setting;
    },
    onWindowSizeChanged() {
      this.curveChart.resize();
    },
    onClickMenu() {
      this.showMenu = !this.showMenu;
    },
    onClickFunction() {
      this.showFunction = !this.showFunction;
    },
    onClickMenuStore() {
      if (window.openCustomUrl) {
        window.openCustomUrl("https://www.ikrusher.com/shop.html");
      } else {
        window.open("https://www.ikrusher.com/shop.html");
      }
    },
    onClickMyBrand() {
      this.setKeepAlive({ type: 'add', name: "MyBrand" })
      this.$router.push({name:'MyBrand',query:  {hash: this.$store.state.hash}})
    },
    onClickMenuAccount() {
      this.showAccountPopup = true;
    },

    onClickClearCache() {
      window.clearAppCache([
        "WKWebsiteDataTypeDiskCache",
        "WKWebsiteDataTypeMemoryCache",
        "WKWebsiteDataTypeOfflineWebApplicationCache",
        "WKWebsiteDataTypeCookies"
      ]);
    },
    onClickSettings() {

      this.$router.push({name:"AppSettings",query: {hash:this.$store.state.hash}})
    },
    click_allFunction_side(e) {
      if (
          e.target.className != "head-menu-show" &&
          e.target.className != "header-menu"
      ) {
        this.showMenu = false;
      }

      if (
          e.target.className !== "head-function-show" &&
          e.target.className !== "header-bluetooth"
      ) {
        this.showFunction = false;
      }
    },
    handleScroll() {
      const scrollTop = this.$refs.content.scrollTop; //dom滚动距离
      const offsetHeight = this.$refs.content.offsetHeight; //dom可视区高度
      const scrollHeight = this.$refs.content.scrollHeight; //dom滚动条总高度

      //判断滚动条距离顶部的位置
      if (scrollTop > 10) {
        this.$refs.maskTop.style.display = "block";
      } else {
        this.$refs.maskTop.style.display = "none";
      }

      //判断滚动条距离底部的位置
      if (scrollTop + offsetHeight >= scrollHeight - 10) {
        this.$refs.maskBottom.style.display = "none";
      } else {
        this.$refs.maskBottom.style.display = "block";
      }
    },
    onROpen() {
      this.prevCoilResistorValue = parseFloat(this.coilResistorValue);
    },
    onLevelDropdown(value) {
      this.currentDeviceLevelItem = value;
      this.currentDeviceLevel = value.value;
      if (this.isD019) {
        this.modelList[this.curveModel].touchSensitivity = value.value;
      }
      this.$refs.item.toggle();
      this.refreshVoltageCurve();
    },
    onChangeRDropdown(value) {
      const r = toFloat(this.coilResistorValue, 1.2);
      let maxPower = toFloat(this.maxPowerValue, 10);
      //从最大功率算回最大电压
      this.limitMaxVoltage = calcVoltageFloorInt(maxPower, r);
      this.limitMinVoltage = calcVoltageCeilInt(0.1, r);
      maxPower = calcMaxPower(this.limitMaxVoltage, r);

      //电阻发生了改变,重新计算power值，这里先算出当前的电压值
      this.modelList.forEach(modelList => {
        if (modelList.id !== 0) {
          modelList.diyVoltage = [].concat(modelList.originDiyVoltage); //不是自定义的话复制原始数据
          modelList.preheatVoltage = modelList.originPreheatVoltage; //不是自定义的话复制原始数据
          modelList.preheatTime = modelList.originPreheatTime; //不是自定义的话复制原始数据
          modelList.isSupportNfc = modelList.originIsSupportNfc; //不是自定义的话复制原始数据
          modelList.isSupportPreheat = modelList.originIsSupportPreheat; //不是自定义的话复制原始数据
        }

        modelList.diyVoltage = modelList.diyVoltage.map(item =>
            handleMaxPowerVoltage(
                item,
                this.limitMaxVoltage,
                this.limitMinVoltage
            )
        );

        modelList.power = modelList.diyVoltage.map(
            item => -Math.abs(calcPower(item, r, maxPower))
        );

        const preheatVoltage = Math.abs(
            handleMaxPowerVoltage(
                modelList.preheatVoltage,
                this.limitMaxVoltage,
                this.limitMinVoltage
            )
        );
        modelList.preheatVoltage = Math.abs(preheatVoltage);
        modelList.preheatPower = calcPower(preheatVoltage, r, maxPower);
      });

      this.refreshVoltageCurve();
    },
    onChangeModeDropdown(value) {
      //保存当前设备的选择模式id
      if (this.isConnected) {
        window.localStorage.setItem(
            `current_select_mode_${this.getLoginInfo().id}_${this.macAddress}`,
            this.modelList[this.curveModel].id
        );
      } else {
        window.localStorage.setItem(
            `current_select_mode_${this.getLoginInfo().id}`,
            this.modelList[this.curveModel].id
        );
      }
      this.onChangeRDropdown(value);
    },
    //刷新echarts
    refreshVoltageCurve() {
      const r = toFloat(this.coilResistorValue, 1.2);
      let maxPower = toFloat(this.maxPowerValue, 10);
      //从最大功率算回最大电压
      this.limitMaxVoltage = calcVoltageFloorInt(maxPower, r);
      maxPower = calcMaxPower(this.limitMaxVoltage, r);

      const voltageData = d019ChartConfig(this.isD019 ? "D019" : "", this.currentDeviceLevel + 1);
      voltageData.series[0].data = this.modelList[this.curveModel].power.map(
          item =>  {
            const current = Math.abs(item)
            return current >= maxPower ? maxPower:current;
          }
      );
      voltageData.yAxis.min = 0.01;
      voltageData.yAxis.max = parseFloat(maxPower.toFixed(2));
      // voltageData.color[0] = this.curveColor;
      // voltageData.series[0].label.color = this.curveColor;
      // voltageData.series[0].areaStyle.color = graphic.LinearGradient(
      //     0,
      //     0,
      //     0,
      //     1,
      //     [
      //       {
      //         offset: 0,
      //         color: this.curveColorLinearGradient[0] //c0dee2
      //       },
      //       {
      //         offset: 1,
      //         color: this.curveColorLinearGradient[1] //f6fafa
      //       }
      //     ]
      // );
      this.curveColor = voltageData.color[0]
      this.curveChart.setOption(voltageData, true);

      // const modelList = this.modelList[this.curveModel];
      // if (
      //     this.isD019 &&
      //     modelList.touchSensitivity < 3 &&
      //     modelList.touchSensitivity >= 0
      // ) {
      //   this.currentDeviceLevel = modelList.touchSensitivity;
      //   this.currentDeviceLevelItem = this.deviceLevelList[
      //       this.currentDeviceLevel
      //       ];
      // }
    },
    onClickEdit() {

      if (this.curveModel === 0) {
        this.onClickSave();
        return;
      }
      const modeList = this.modelList[this.curveModel];
      if (modeList.isRecommend) {
        this.renameMode = modeList;
        this.rename = modeList.modeName;
        this.memoName = modeList.remark;
        this.showRenamePopup = true;
      } else {
        this.editName = modeList.modeName;
        this.memoName = modeList.remark;
        this.showEditPopup = true;
      }
    },
    onClickEditConfirm() {
      if (this.$utils.isNullAndEmpty(this.editName)) {
        this.$toast({
          message: "Please enter mode name."
        });
        return;
      }
      this.$api.User.updateVoltageName({
        id: this.modelList[this.curveModel].id,
        modeName: this.editName,
        heatingRemarks: this.memoName
      }).then(res => {
        if (res.code == 200) {
          this.$toast({
            type: "success",
            message: res.message
          });
          this.modelList[this.curveModel].modeName = this.editName;
          this.modelList[this.curveModel].remark = this.memoName;
          this.option1[this.curveModel].text = this.editName;
          this.showEditPopup = false;
          this.editName = "";
          this.memoName = "";
        } else {
          this.$toast({
            type: "fail",
            message: res.message,
            duration: 2000
          });
        }
      });
    },
    onClickSave(showTip = true) {
      const modeList = this.modelList[this.curveModel];
      if (this.curveModel === 0) {
        this.showSavePopup = true;
      } else if (modeList.isRecommend) {
        //保存推荐列表的别名
        const recommendAliasNameList = this.getRecommendAliasNameList();
        let recommendAliasNameIndex = recommendAliasNameList.findIndex(
            item => item.id === modeList.id
        );
        if (recommendAliasNameIndex === -1) {
          //已保存的索引不存在，在这个列表上push新增
          const writerSetting = new WriterSetting();
          Object.assign(writerSetting, modeList);
          writerSetting.diyVoltage = [].concat(
              modeList.diyVoltage.map(item => Math.abs(item))
          );
          this.copyOriginWriterSetting(writerSetting, writerSetting);
          this.copyOriginWriterSetting(writerSetting, modeList);
          // if (
          //     this.isD019 &&
          //     this.currentDeviceLevel < 3 &&
          //     this.currentDeviceLevel >= 0
          // ) {
          //   writerSetting.touchSensitivity = this.currentDeviceLevel;
          // }
          recommendAliasNameList.push(writerSetting);
        } else {
          const localSaveWriterSetting =
              recommendAliasNameList[recommendAliasNameIndex];
          this.copyWriterSetting(modeList, localSaveWriterSetting);
          this.copyOriginWriterSetting(localSaveWriterSetting, modeList);

          // if (
          //     this.isSupportLevel &&
          //     this.currentDeviceLevel < 3 &&
          //     this.currentDeviceLevel >= 0
          // ) {
          //   localSaveWriterSetting.touchSensitivity = this.currentDeviceLevel;
          // }
        }

        this.$store.commit("setRecommendAliasNameList", recommendAliasNameList);
        if (showTip) {
          this.$toast({
            type: "success",
            message: "Modified success"
          });
        }
      } else {
        this.onClickSaveConfirm();
      }
    },
    onClickSaveConfirm() {
      let saveName = "";
      const isNewMode = this.curveModel === 0;
      if (isNewMode) {
        if (this.$utils.isNullAndEmpty(this.saveName)) {
          this.$toast({
            message: "Please enter mode name."
          });
          return;
        }
        saveName = this.saveName;
      } else {
        saveName = this.modelList[this.curveModel].modeName;
      }

      const r =
          parseFloat(this.coilResistorValue).toString() === "NaN"
              ? 1
              : parseFloat(this.coilResistorValue);

      const modelList = this.modelList[this.curveModel];

      //更新保存的数据
      this.copyOriginWriterSetting(modelList, modelList);

      const saveModel = {
        id: this.modelList[this.curveModel].id,
        modeName: saveName,
        nfcSettings: this.modelList[this.curveModel].isSupportNfc ? 1 : 0,
        preheatSetting: this.modelList[this.curveModel].isSupportPreheat
            ? 1
            : 0,
        preheatTime: this.modelList[this.curveModel].preheatTime,
        preheatVoltage: Math.abs(modelList.preheatVoltage),
        resistance: r,
        temperature: modelList.diyVoltage.map(item => Math.abs(item)).join(","),
        description: this.memoName
      };
      this.$api.User.saveVoltageSettings(saveModel).then(res => {
        // log(res);
        if (res.code == 200) {
          this.showSavePopup = false;
          this.saveName = "";
          this.memoName = "";
          if (isNewMode) {
            this.loadData(true);
          } else {
            modelList.remark = this.memoName;
          }
          setTimeout(() => {
            this.$toast({
              type: "success",
              message: res.message
            });
          }, 500);
        } else {
          this.$toast({
            type: "fail",
            message: res.message,
            duration: 2000
          });
        }
      });
    },
    onTouchmoveVoltage(e) {
    },
    onChangeVoltageCurve(index) {
      //重新计算power
      this.modelList[this.curveModel].power[index] = -calcPower(
          this.modelList[this.curveModel].diyVoltage[index],
          parseFloat(this.coilResistorValue),
          parseFloat(this.maxPowerValue)
      );
      this.refreshVoltageCurve();
    },
    onClickTimesLongClickStart(type) {
      if (!this.modelList[this.curveModel].isSupportPreheat) {
        return;
      }
      clearTimeout(this.preheatTimeTimeoutId);

      this.preheatTimeTimeoutId = setTimeout(() => {
        clearInterval(this.preheatTimeIntervalId);
        this.preheatTimeLongClickIsRun = true;
        this.preheatTimeIntervalId = setInterval(() => {
          this.handleTime(type);
        }, 100);
      }, 300);
    },
    onClickTimesLongClickEnd(type) {
      if (!this.modelList[this.curveModel].isSupportPreheat) {
        return;
      }
      clearTimeout(this.preheatTimeTimeoutId);
      clearInterval(this.preheatTimeIntervalId);
      if (this.preheatTimeLongClickIsRun) {
        this.preheatTimeLongClickIsRun = false;
        this.preheatTimeIntervalId = -1;
      } else {
        this.handleTime(type);
      }
    },
    onClickVoltageLongClickStart(type) {
      if (!this.modelList[this.curveModel].isSupportPreheat) {
        return;
      }
      clearTimeout(this.preheatVoltageTimeoutId);

      this.preheatVoltageTimeoutId = setTimeout(() => {
        clearInterval(this.preheatVoltageIntervalId);
        this.preheatVoltageLongClickIsRun = true;
        this.preheatVoltageIntervalId = setInterval(() => {
          this.handleVoltage(type);
        }, 100);
      }, 300);
    },
    onClickVoltageLongClickEnd(type) {
      if (!this.modelList[this.curveModel].isSupportPreheat) {
        return;
      }
      clearTimeout(this.preheatVoltageTimeoutId);
      clearInterval(this.preheatVoltageIntervalId);
      if (this.preheatVoltageLongClickIsRun) {
        this.preheatVoltageLongClickIsRun = false;
        this.preheatTimeIntervalId = -1;
      } else {
        this.handleVoltage(type);
      }
    },
    handleTime(type) {
      const writerSetting = this.modelList[this.curveModel];
      if (type === "+") {
        if (writerSetting.preheatTime >= 20000) {
          writerSetting.preheatTime = 20000;
          clearInterval(this.preheatTimeIntervalId);
          return;
        }
        writerSetting.preheatTime += 500;
      } else {
        if (writerSetting.preheatTime <= 0) {
          writerSetting.preheatTime = 0;
          clearInterval(this.preheatTimeIntervalId);
          return;
        }
        writerSetting.preheatTime -= 500;
      }
    },
    handleVoltage(type) {
      const r = toFloat(this.coilResistorValue, 1.2);
      const maxPower = toFloat(this.maxPowerValue, 10);

      const writerSetting = this.modelList[this.curveModel];
      let currentVoltage = writerSetting.preheatVoltage; //矫正数据;
      if (type === "+") {
        if (currentVoltage >= this.limitMaxVoltage) {
          clearInterval(this.preheatVoltageIntervalId);
          writerSetting.preheatVoltage = this.limitMaxVoltage;
          return;
        }
        writerSetting.preheatVoltage += 100;
        writerSetting.preheatPower = calcPower(
            writerSetting.preheatVoltage,
            r,
            maxPower
        );
      } else {
        if (currentVoltage <= this.limitMinVoltage) {
          clearInterval(this.preheatVoltageIntervalId);
          writerSetting.preheatVoltage = this.limitMinVoltage;
          return;
        }
        writerSetting.preheatVoltage -= 100;
        writerSetting.preheatPower = calcPower(
            writerSetting.preheatVoltage,
            r,
            maxPower
        );
      }
    },
    async onClickUpload() {
      if (!this.isConnected) {
        this.$toast({
          type: "fail",
          duration: "1000",
          position: "center",
          message: "Not connected"
        });
        return;
      }

      if (parseFloat(this.coilResistorValue).toString() === "NaN") {
        this.$toast({
          type: "fail",
          duration: "1000",
          position: "center",
          message: "Resistance require number"
        });
        return;
      }
      let r = toFloat(this.coilResistorValue, 1.2);
      if (r.toString() === "NaN") {
        r = 1.2;
      }
      const maxPower = toFloat(this.maxPowerValue, 10);
      this.currentSetR = r;
      const writerSetting = new WriterSetting();
      //功率换算回电压
      const modelList = this.modelList[this.curveModel];
      // if (
      //     this.isSupportLevel &&
      //     this.currentDeviceLevel < 3 &&
      //     this.currentDeviceLevel >= 0
      // ) {
      //   modelList.touchSensitivity = this.currentDeviceLevel;
      // }
      const diyVoltage = modelList.diyVoltage.map(item => Math.abs(item));
      //有档位的设备,处理一下第一个字节位为档位
      Object.assign(writerSetting, this.modelList[this.curveModel]);
      writerSetting.diyVoltage = diyVoltage;
      console.log(
          `写入的档位：${this.currentDeviceLevel} ，是否支持档位写入：${this.isSupportLevel}`
      );
      try {
        await bluetoothRepository.writeToWriter(
            writerSetting,
            this.isSupportLevel,
            this.currentDeviceLevel
        );
        if (modelList.isRecommend) {
          this.onClickSave(false);
        } else {
          this.copyOriginWriterSetting(modelList, modelList);
        }
        this.$toast({
          type: "success",
          message: "Upload success"
        });
      } catch (e) {
        this.$toast({
          type: "fail",
          duration: "1000",
          position: "center",
          message: "Send fail!"
        });
        return;
      }

      //上传到服务器
      let hw = "";
      let fw = "";
      let deviceVersion = this.deviceVersion.split("&");
      //解析设备的版本信息
      if (deviceVersion.length === 2) {
        hw = parseInt(deviceVersion[0].replace("HW", ""), 16);
        fw = parseInt(deviceVersion[1].replace("FW", ""));
      }
      const resp = device.uploadConfig(
          this.modelList[this.curveModel].id,
          this.modelList[this.curveModel].modeName,
          r,
          maxPower,
          this.cartridgeFlag,
          this.macAddress,
          this.deviceVersion,
          navigator.bluetooth2 ? process.env.APP_ANDROID_VERSION : process.env.APP_IOS_VERSION,
          writerSetting
      );
    },
    onClickHistory() {
      this.$router.push({name:"History",query: {hash:this.$store.state.hash}});
    },
    onClickGotoConnect() {
      localStorage.setItem('last_visit_path', 'Connect')
      this.$router.replace({name:"Connect",query: {hash: this.$store.state.hash}});
    },
    onClickConnectBlue() {
      this.showFunction = false;
      if (this.isConnected) {
        this.showDisconnectPopup = true;
      } else {
        bluetoothRepository.startPair();
        //如果浏览器有receiveScanDevice发送蓝牙设备列表则走web方式，没有走原生
        if (Object.prototype.hasOwnProperty.call(window, "receiveScanDevice")) {
          this.showBluetoothPopup = true;
        }
      }
    },
    onClickCloseBluetoothPopup() {
      clearTimeout(this.cancelSearchTimeoutId);
      bluetoothRepository.cancelSearch();
      this.showBluetoothPopup = false;
    },
    touchstart(e) {
    },
    touchmove(e) {
    },
    onClickSwitchLeft() {
      if (this.currentDeviceIndex > 0) {
        this.currentDeviceIndex -= 1;
        this.$refs.deviceSwipe.swipeTo(this.currentDeviceIndex);
      }
      // console.log(this.$refs.deviceList);
      // const imgWidth = document.getElementsByClassName("hub-img")[0]
      //   .clientWidth;
    },
    onClickSwitchRight() {
      if (this.currentDeviceIndex + 1 < this.deviceList.length) {
        this.currentDeviceIndex += 1;
        this.$refs.deviceSwipe.swipeTo(this.currentDeviceIndex);
      }
      // const imgWidth = document.getElementsByClassName("hub-img")[0]
      //   .clientWidth;
      // this.$refs.deviceList.scrollLeft += imgWidth;
      // console.log(imgWidth);
      // console.log(this.$refs.deviceList.scrollLeft);
    },
    onClickConnectDevice() {
      if (this.isConnected) {
        return;
      }
      const device = this.deviceList[this.currentDeviceIndex];
      if (device) {
        bluetoothRepository.connectDevice(device, true);
        this.$toast({
          type: "loading",
          message: "Connecting...",
          duration: 10000,
          forbidClick: true //禁止背景点击
        });
        this.connectTimeoutId = setTimeout(() => {
          if (!this.isConnected) {
            this.$toast({
              type: "fail",
              message: "Connection failed",
              duration: 2000,
              forbidClick: true
            });
          }
        }, 10000);
      }
    },
    onClickDisconnectCancel() {
      this.showDisconnectPopup = false;
    },
    onClickDisconnectSure() {
      bluetoothRepository.disconnect();
      this.currentDeviceIndex = 0;
      this.showDisconnectPopup = false;
    },
    onShowDeviceSetting() {
      if (!this.isConnected) {
        this.$toast({
          type: "fail",
          duration: "1000",
          position: "center",
          message: "Not connected"
        });
        return;
      }

      if (parseFloat(this.coilResistorValue).toString() === "NaN") {
        this.$toast({
          type: "fail",
          duration: "1000",
          position: "center",
          message: "Resistance require number"
        });
        return;
      }
      this.showSettingsPopup = true;
    },
    onCloseDeviceSetting() {
      this.showSettingsPopup = false;
    },
    onPreheatChange(value) {
      // if (this.modelList[this.curveModel].isRecommend) {
      //   this.curveModel = 0;
      // }
      this.refreshVoltageCurve();
      clearTimeout(this.preheatTimeTimeoutId);
      clearTimeout(this.preheatVoltageTimeoutId);
      clearInterval(this.preheatTimeIntervalId);
      clearInterval(this.preheatVoltageIntervalId);
      this.preheatTimeLongClickIsRun = false;
      this.preheatVoltageLongClickIsRun = false;
    },
    onNfcChange(value) {
      // if (this.modelList[this.curveModel].isRecommend) {
      //   this.curveModel = 0;
      // }
      // this.refreshVoltageCurve();
      // clearTimeout(this.preheatTimeTimeoutId);
      // clearTimeout(this.preheatVoltageTimeoutId);
      // clearInterval(this.preheatTimeIntervalId);
      // clearInterval(this.preheatVoltageIntervalId);
      // this.preheatTimeLongClickIsRun = false;
      // this.preheatVoltageLongClickIsRun = false;
    },
    onRecommendModeClick(clickItem) {
      const targetIndex = this.modelList.findIndex(
          item => item.id === clickItem.id
      );
      if (targetIndex !== -1) {
        this.curveModel = targetIndex;
      }
      this.onChangeModeDropdown(1);
    },
    handleClickLong(mode) {
      this.renameMode = mode;
      this.rename = mode.modeName;
      this.memoName = mode.remark;
      this.showRenamePopup = true;
    },
    onClickRenameCancel() {
      // this.showRenamePopup = false;
      Dialog.confirm({
        title: "Tips",
        message: "Restoring default settings will lose previous data"
      })
          .then(async () => {
            const r = toFloat(this.coilResistorValue, 1.2);
            let maxPower = toFloat(this.maxPowerValue, 10);
            //从最大功率算回最大电压
            this.limitMaxVoltage = calcVoltageFloorInt(maxPower, r);
            this.limitMinVoltage = calcVoltageCeilInt(0.1, r);
            maxPower = calcMaxPower(this.limitMaxVoltage, r);

            try {
              const data = await device.deviceVoltages({
                modelIdentification: this.isConnected ? "HB" : ""
              });

              //恢复之前查询服务器的数据
              const serverRecommendData = data.filter(
                  item => item.recommendStatus === 0
              );
              const newRecommendDataIndex = serverRecommendData.findIndex(
                  item => item.id === this.renameMode.id
              );
              if (newRecommendDataIndex !== -1) {
                //服务器的值
                const item = serverRecommendData[newRecommendDataIndex];
                //更新到本地存储
                const recommendAliasNameList = this.getRecommendAliasNameList();
                const aliasNameItemIndex = recommendAliasNameList.findIndex(
                    item => item.id === this.renameMode.id
                );
                if (aliasNameItemIndex !== -1) {
                  //本地存储有这个id的，更新成服务器的值
                  const localSaveRecommendMode =
                      recommendAliasNameList[aliasNameItemIndex];

                  //要更新一下renameMode的电压参数
                  localSaveRecommendMode.serverData.diyVoltage = item.heatingVoltage
                      .split(",")
                      .map(item =>
                          handleMaxPowerVoltage(
                              Math.floor(item / 100) * 100,
                              this.limitMaxVoltage,
                              this.limitMinVoltage
                          )
                      ); //矫正数据
                  localSaveRecommendMode.serverData.modeName = item.modeName;
                  localSaveRecommendMode.serverData.isSupportNfc =
                      item.nfcSettings === 1;
                  localSaveRecommendMode.serverData.isSupportPreheat =
                      item.preheatSetting === 1;
                  localSaveRecommendMode.serverData.preheatTime =
                      item.preheatTime;
                  localSaveRecommendMode.serverData.preheatVoltage = Math.abs(
                      handleMaxPowerVoltage(
                          Math.floor(Math.abs(item.preheatVoltage) / 100) * 100,
                          this.limitMaxVoltage,
                          this.limitMinVoltage
                      )
                  );
                  localSaveRecommendMode.serverData.remark = item.heatingRemarks;
                  localSaveRecommendMode.serverData.power = this.renameMode.serverData.diyVoltage.map(
                      item => calcPower(item, r, maxPower) * -1
                  );
                  localSaveRecommendMode.serverData.isRecommend =
                      item.recommendStatus === 0;
                  localSaveRecommendMode.serverData.preheatPower = calcPower(
                      Math.abs(
                          handleMaxPowerVoltage(
                              Math.floor(Math.abs(item.preheatVoltage) / 100) * 100,
                              this.limitMaxVoltage,
                              this.limitMinVoltage
                          )
                      ),
                      r,
                      maxPower
                  );
                  this.copyWriterSetting(
                      localSaveRecommendMode.serverData,
                      localSaveRecommendMode
                  );

                  //更新列表的数据
                  this.copyWriterSetting(
                      localSaveRecommendMode.serverData,
                      this.renameMode
                  );
                  this.copyOriginWriterSetting(this.renameMode, this.renameMode);
                  this.renameMode.modeName =
                      localSaveRecommendMode.serverData.modeName;
                  this.renameMode.remark =
                      localSaveRecommendMode.serverData.remark;
                  this.renameMode.power = localSaveRecommendMode.diyVoltage.map(
                      item => calcPower(item, r, maxPower)
                  );
                  this.renameMode.preheatPower = calcPower(
                      localSaveRecommendMode.preheatPower,
                      r,
                      maxPower
                  );

                  window.localStorage.setItem(
                      `alias_recommend_name_list_${this.getLoginInfo().id}_${
                          this.macAddress
                      }`,
                      JSON.stringify(recommendAliasNameList)
                  );

                  this.$store.commit(
                      "setRecommendAliasNameList",
                      recommendAliasNameList
                  );
                } else {
                  //已保存的索引不存在，在这个列表上push新增
                  const writerSetting = new WriterSetting();
                  Object.assign(writerSetting, this.renameMode);
                  writerSetting.diyVoltage = [].concat(
                      this.renameMode.diyVoltage.map(item => Math.abs(item))
                  );
                  this.copyOriginWriterSetting(writerSetting, writerSetting);
                  this.copyOriginWriterSetting(writerSetting, this.renameMode);
                  Object.assign(
                      writerSetting.serverData,
                      this.renameMode.serverData
                  );
                  recommendAliasNameList.push(writerSetting);

                  window.localStorage.setItem(
                      `alias_recommend_name_list_${this.getLoginInfo().id}_${
                          this.macAddress
                      }`,
                      JSON.stringify(recommendAliasNameList)
                  );
                  this.$store.commit(
                      "setRecommendAliasNameList",
                      recommendAliasNameList
                  );
                }
              } else {
                this.defaultLocalSaveRestore();
              }
            } catch (e) {
              this.defaultLocalSaveRestore();
            }

            //恢复服务器的曲线设置，包括名称备注
            const targetIndex = this.option1.findIndex(
                item => item.id === this.renameMode.id
            );

            if (targetIndex !== -1) {
              this.option1[targetIndex].text = this.renameMode.modeName;
            }

            this.showRenamePopup = false;
            this.refreshVoltageCurve();
            this.$toast({
              type: "success",
              message: "Restore success."
            });
          })
          .catch(() => {
          });
    },
    onClickRenameConfirm(restoreDefaultSetting = false) {
      if (this.rename === '') {
        this.onClickSave(false)
        return
      }
      //保存到本地
      this.renameMode.modeName = this.rename;
      this.renameMode.remark = this.memoName;
      const targetIndex = this.option1.findIndex(
          item => item.id === this.renameMode.id
      );
      if (targetIndex !== -1) {
        this.option1[targetIndex].text = this.rename;
      }
      //保存推荐列表的别名
      const recommendAliasNameList = this.getRecommendAliasNameList();
      let recommendAliasNameIndex = recommendAliasNameList.findIndex(
          item => item.id === this.renameMode.id
      );
      if (recommendAliasNameIndex === -1) {
        //已保存的索引不存在，在这个列表上push新增
        recommendAliasNameList.push(this.renameMode);
      } else {
        const recommendAliasName =
            recommendAliasNameList[recommendAliasNameIndex];
        recommendAliasName.modeName = this.rename;
        recommendAliasName.remark = this.memoName;
      }
      window.localStorage.setItem(
          `alias_recommend_name_list_${this.getLoginInfo().id}_${
              this.macAddress
          }`,
          JSON.stringify(recommendAliasNameList)
      );

      this.$store.commit("setRecommendAliasNameList", recommendAliasNameList);

      this.showRenamePopup = false;
      this.$toast({
        type: "success",
        message: "Modified success."
      });
    },

    onClickDeleteModeCancel() {
      this.showDeleteModePopup = false;
    },
    async onClickDeleteModeConfirm() {
      try {
        this.$toast({
          type: "loading",
          message: "Deleting...",
          duration: 5000,
          forbidClick: true //禁止背景点击
        });
        const id = this.waitDeleteMode.id;
        await this.$api.User.deleteModeName({ id });
        const currentSelected = this.option1.findIndex(
            item => item.value === this.curveModel
        );
        const selectedItem = this.option1[currentSelected];

        let index = this.option1.findIndex(item => item.id === id);
        if (index !== -1) {
          this.option1.splice(index, 1);
          this.option1 = this.option1.map((item, index) => {
            item.value = index;
            return item;
          });
        }
        index = this.modelList.findIndex(item => item.id === id);
        if (index !== -1) {
          this.modelList.splice(index, 1);
        }
        index = this.option1.findIndex(item => item.id === selectedItem.id);
        if (index === -1) {
          this.curveModel = 0;
          this.refreshVoltageCurve();
        } else {
          this.curveModel = index;
          this.refreshVoltageCurve();
        }
        this.waitDeleteMode = null;
        // this.$nextTick(() => {
        //   this.$refs.dropdown.toggle();
        // });
        this.$toast("Delete successfully");
      } catch (e) {
        this.$toast("Delete failure");
      } finally {
        this.showDeleteModePopup = false;
      }
    },
    onPlusPower(index) {
      const currentVoltage = this.modelList[this.curveModel].diyVoltage;
      let voltage = Math.abs(currentVoltage[index]);
      voltage += 100;
      if (voltage >= this.limitMaxVoltage) {
        voltage = this.limitMaxVoltage;
      }
      this.$set(currentVoltage, index, -voltage);
      this.modelList[this.curveModel].power[index] = -calcPower(
          voltage,
          toFloat(this.coilResistorValue, 1.0),
          toFloat(this.maxPowerValue, 10)
      );
      this.refreshVoltageCurve();
    },
    onReducePower(index) {
      const currentVoltage = this.modelList[this.curveModel].diyVoltage;
      let voltage = Math.abs(currentVoltage[index]);
      voltage -= 100;
      if (voltage < this.limitMinVoltage) {
        voltage = this.limitMinVoltage;
      }
      this.$set(currentVoltage, index, -voltage);
      this.modelList[this.curveModel].power[index] = -calcPower(
          voltage,
          toFloat(this.coilResistorValue, 1.0),
          toFloat(this.maxPowerValue, 10)
      );
      this.refreshVoltageCurve();
    },

    async loadDeviceSetting(showLoading = true) {
      if (this.isConnected) {
        this.queryDeviceTimeoutId = setTimeout(() => {
          if (this.retryQueryTimes > 0) {
            this.retryQueryTimes -= 1;
            this.loadDeviceSetting(true);
          } else {
            this.$toast.fail("Read device coil resistor timeout");
            this.retryQueryTimes = 3;
          }
        }, 2000);

        try {
          if (showLoading) {
            this.$toast({
              type: "loading",
              message: "Reading coil resistor..."
            });
          }
          await bluetoothRepository.queryWriter();
          console.log(`清除定时器`);
          clearTimeout(this.queryDeviceTimeoutId);
          this.queryDeviceTimeoutId = -1;
          if (showLoading) {
            this.$toast.clear();
          }
          this.retryQueryTimes = 3;
        } catch (e) {
          clearTimeout(this.queryDeviceTimeoutId);
          this.queryDeviceTimeoutId = -1;
          // if (showLoading) {
          setTimeout(() => {
            this.$toast.fail(`${e} Current coil resistor is 1.2ohm`);
          }, 1000);

          // }
        }
      } else {
        this.$toast({
          type: "fail",
          duration: "2000",
          position: "center",
          message: "No coil connected, the preset resistor is 1.2 ohm."
        });
      }
    },

    defaultLocalSaveRestore() {
      const r = toFloat(this.coilResistorValue, 1.2);
      let maxPower = toFloat(this.maxPowerValue, 10);
      //从最大功率算回最大电压
      this.limitMaxVoltage = calcVoltageFloorInt(maxPower, r);
      this.limitMinVoltage = calcVoltageCeilInt(0.1, r);
      maxPower = calcMaxPower(this.limitMaxVoltage, r);

      //网络异常或者其他异常，读取设置回之前保存的
      //保存推荐列表的别名
      const recommendAliasNameList = this.getRecommendAliasNameList();
      let recommendAliasNameIndex = recommendAliasNameList.findIndex(
          item => item.id === this.renameMode.id
      );

      if (recommendAliasNameIndex !== -1) {
        const recommendAliasName =
            recommendAliasNameList[recommendAliasNameIndex];
        recommendAliasName.isRecommend = 1;
        this.copyWriterSetting(
            recommendAliasName.serverData,
            recommendAliasName
        );
        this.copyWriterSetting(recommendAliasName, this.renameMode);
        this.copyOriginWriterSetting(this.renameMode, this.renameMode);

        this.renameMode.modeName = recommendAliasName.serverData.modeName;
        this.renameMode.remark = recommendAliasName.serverData.remark;
        this.renameMode.power = recommendAliasName.diyVoltage.map(item =>
            calcPower(item, r, maxPower)
        );
        this.renameMode.preheatPower = calcPower(
            recommendAliasName.preheatPower,
            r,
            maxPower
        );

        window.localStorage.setItem(
            `alias_recommend_name_list_${this.getLoginInfo().id}_${
                this.macAddress
            }`,
            JSON.stringify(recommendAliasNameList)
        );

        this.$store.commit("setRecommendAliasNameList", recommendAliasNameList);
      } else {
        //已保存的索引不存在，在这个列表上push新增
        const writerSetting = new WriterSetting();
        Object.assign(writerSetting, this.renameMode);
        writerSetting.diyVoltage = [].concat(
            this.renameMode.diyVoltage.map(item => Math.abs(item))
        );
        this.copyOriginWriterSetting(writerSetting, writerSetting);
        this.copyOriginWriterSetting(writerSetting, this.renameMode);
        writerSetting.serverData = Object.assign(this.renameMode.serverData);
        recommendAliasNameList.push(writerSetting);

        window.localStorage.setItem(
            `alias_recommend_name_list_${this.getLoginInfo().id}_${
                this.macAddress
            }`,
            JSON.stringify(recommendAliasNameList)
        );
        this.$store.commit("setRecommendAliasNameList", recommendAliasNameList);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 75px;
    background: #f1f2f2;
    display: flex;
    align-items: center;
    position: relative;

    .header-menu {
      position: absolute;
      left: 26px;
      width: 29px;
      height: 22px;
    }

    .header-logo {
      width: 138px;
      margin: 0 auto;
    }


    @font-face {
      font-family: "Street - Expanded Semi";
      src: url("../assets/font/street.ttf");
    }

    .brand-logo {
      font-family: "Street - Expanded Semi";
      color: #535453;
      flex:1;
      font-size: 28px;
      height: 50px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .brand-logo-icon {
        width: auto;
        margin:0 70px;
        height: 50px;
      }
    }

    .header-bluetooth {
      position: absolute;
      right: 16px;
      width: 35px;
      height: 35px;
    }

    .head-menu-show {
      position: absolute;
      left: 26px;
      top: 58px;
      z-index: 9999;
      display: flex;
      flex-direction: column;

      .triangle {
        width: 27px;
        height: 17px;
      }

      .menu-wrap {
        background: #545554;
        display: flex;
        padding: 15px;
        min-width:152px;
        margin: -5px 0 0 -15px;
        border-radius: 10px;
        flex-direction: column;
        .menu-item {
          display: flex;
          align-items: center;
          margin-top:20px;
          &:first-child {
            margin-top:0
          }
        }
      }


      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      span {
        font-size: 18px;
        font-family: Roboto-Bold-, Roboto-Bold;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .head-function-show {
      position: absolute;
      right: 12px;
      top: 58px;
      z-index: 9999;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      min-width:203px;

      .triangle {
        width: 27px;
        height: 17px;
      }

      .menu-wrap {
        background: #545554;
        display: flex;
        padding: 15px;
        margin: -5px 0 0 -15px;
        border-radius: 10px;
        flex-direction: column;
        .menu-item {
          display: flex;
          align-items: center;
          margin-top:20px;
          &:first-child {
            margin-top:0
          }
        }
        .first-round {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .last-round {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .round {
          border-radius: 10px;
        }
      }

      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      span {
        font-size: 18px;
        font-family: Roboto-Bold-, Roboto-Bold;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  .mask-top {
    position: absolute;
    top: 75px;
    width: 100%;
    height: 60px;
    background: linear-gradient(360deg, transparent 0%, #e5e5e5 100%);
    z-index: 999;
    pointer-events: none;
  }

  .content {
    position: absolute;
    width: 100%;
    margin-top: 75px;
    overflow-y: auto;
    height: calc(100vh - 175px);

    .content-setting {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px 10px 15px;

      .setting-menu {
        //width: 210px;

        flex: 1;
        /deep/ .van-dropdown-menu__bar {
          border: 1px solid #d1d3d4;
          background: #ffffff;
          border-radius: 8px;
          height: 37px;
          box-shadow: none;

          .van-ellipsis {
            color: #939598;
            margin-left: 10px;
            font-size: 17px;
          }

          .van-dropdown-menu__item {
            justify-content: flex-start;
          }

          .van-dropdown-menu__title {
            width: 92%;
          }

          .van-dropdown-menu__title--active {
            color: #939598;
          }

          .van-dropdown-menu__title::after {
            top: 38%;
            color: #d1d3d4;
            border: 5px solid #d1d3d4;
            border-color: transparent transparent #d1d3d4 #d1d3d4;
          }
        }

        /deep/ .van-dropdown-item {
          left: 13px;
          width: calc(100% - 72px);
          height: 30%;
          border-radius: 8px;

          .van-overlay {
            height: auto;
          }

          .van-dropdown-item__content {
            border-radius: 8px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
            top: 1px;
            left: 3px;
            right: 6px;
            width: auto;
          }

          .van-cell {
            height: 40px;
          }

          .van-cell::after {
            display: none;
          }

          .van-cell__title {
            padding-left: 3px;
            font-size: 17px;
            color: #939598;
            white-space: nowrap;
          }

          .van-dropdown-item__option--active {
            background: var(--dropDownActiveColor);
            border-radius: 5px;

            .van-cell__title {
              color: #ffffff !important;
            }
          }

          .van-dropdown-item__icon {
            color: #6649c4;
          }

          .van-dropdown-item__icon::before {
            display: none;
          }
        }
      }

      .setting-edit {
        width: 35px;
        height: 35px;
        margin-left:15px;
      }

      .setting-save {
        width: 26px;
        height: 26px;
      }
    }

    .content-title {
      font-size: 22px;
      font-weight: normal;
      color: #939598;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #d1d3d4;
    }

    .voltage-one {
      margin-top: 10px;
    }

    .voltage-two {
      display: flex;
      margin: 10px 0 10px 0;

      .voltage-two-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .voltage-plus {
          margin-bottom: 10px;
        }

        .voltage-reduce {
          margin-top: 10px;
        }
      }

      /deep/ .van-slider {
        margin: 10px auto;
      }

      .slider-button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #ffffff;
      }
    }

    .voltage-three {
      display: flex;
      width: 100%;

      div {
        margin: 0 auto;
        font-size: 13px;
        font-weight: 400;
        color: #999999;
      }
    }

    .coli-setting {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      color: #939598;
      padding: 0 16px;
      margin-top: 28px;

      .coil-resistor {
        width: 57%;
        display: flex;
        align-items: center;

        input {
          width: 40px;
          height: 25px;
          line-height: 25px;
          border-radius: 5px;
          border: 1px solid #d1d3d4;
          border-right: none;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          background: #ffffff;
          font-size: 11px;
          color: #939598;
          padding-left: 8px;
        }

        .resistance-value {
          height: 25px;
          line-height: 25px;
          border-radius: 5px;
          border: 1px solid #d1d3d4;
          background: #ffffff;
          font-size: 11px;
          color: #939598;
          padding: 0 8px;
        }

        .refresh-icon {
          padding: 8px;
          width: 20px;
          height: 20px;
        }

        .resistor-input {
          position: relative;
        }

        .resistor-input {
          position: relative;

          input:disabled,
          input[disabled] {
            color: #939598 !important;
            opacity: 1 !important;
            -webkit-text-fill-color: #939598 !important;
          }
        }

        .resistor-input::after {
          font-size: 11px;
          content: "ohm";
          color: #939598;
          position: absolute;
          top: 43%;
          right: 0.20667rem;
          margin-top: -0.13333rem;
        }

        /deep/ .van-dropdown-menu__bar {
          width: 30px;
          height: 25px;
          border-radius: 5px;
          border: 1px solid #d1d3d4;
          border-left: none;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          background: transparent;
          box-shadow: none;

          .van-dropdown-item__content {
            max-height: 100px;
          }

          .van-dropdown-menu__item {
            justify-content: flex-start;
          }

          .van-dropdown-menu__title {
            font-size: 11px;
            color: #939598;
            margin-bottom: 3.5px;

            .van-ellipsis {
              width: 1px;
              color: #fff;
            }
          }

          .van-dropdown-menu__title--active {
            color: #939598;
          }

          .van-dropdown-menu__title::after {
            color: #d1d3d4;
            border: 4px solid #d1d3d4;
            border-color: transparent transparent #d1d3d4 #d1d3d4;
          }
        }

        /deep/ .van-dropdown-item {
          left: 28%;
          width: 27%;
          height: 30%;
          border-radius: 5px;

          .van-overlay {
            height: auto;
          }

          .van-dropdown-item__content {
            border-radius: 5px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
            top: 1px;
            left: 5px;
            right: 5px;
            width: auto;
            max-height: 100px;
          }

          .van-cell {
            height: 25px;
            line-height: 25px;
            padding: 0 0 0 6px;
          }

          .van-cell::after {
            display: none;
          }

          .van-cell__title {
            padding-left: 3px;
            font-size: 11px;
            color: #939598;
            white-space: nowrap;
          }

          .van-dropdown-item__option--active {
            background: var(--dropDownActiveColor);
            border-radius: 5px;

            .van-cell__title {
              color: #ffffff !important;
            }
          }

          .van-dropdown-item__icon {
            color: #6649c4;
          }

          .van-dropdown-item__icon::before {
            display: none;
          }
        }
      }

      .max-power {
        width: 43%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        /deep/ .van-dropdown-menu__bar {
          width: 55px;
          height: 25px;
          border-radius: 5px;
          border: 1px solid #d1d3d4;
          box-shadow: none;

          .van-dropdown-menu__item {
            justify-content: flex-start;
          }

          .van-dropdown-menu__title {
            font-size: 11px;
            color: #939598;
            padding-right: 12px;
          }

          .van-dropdown-menu__title--active {
            color: #939598;
          }

          .van-dropdown-menu__title::after {
            top: 42%;
            color: #d1d3d4;
            border: 4px solid #d1d3d4;
            border-color: transparent transparent #d1d3d4 #d1d3d4;
          }
        }

        /deep/ .van-dropdown-item {
          left: 79%;
          width: 18%;
          height: 30%;
          border-radius: 5px;

          .van-overlay {
            height: auto;
          }

          .van-dropdown-item__content {
            border-radius: 5px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
            top: 1px;
            left: 6px;
            right: 5px;
            width: auto;
            max-height: 100px;
          }

          .van-cell {
            height: 25px;
            line-height: 25px;
            padding: 0 0 0 6px;
          }

          .van-cell::after {
            display: none;
          }

          .van-cell__title {
            padding-left: 3px;
            font-size: 11px;
            color: #939598;
            white-space: nowrap;
          }

          .van-dropdown-item__option--active {
            background: var(--dropDownActiveColor);
            border-radius: 5px;

            .van-cell__title {
              color: #ffffff !important;
            }
          }

          .van-dropdown-item__icon {
            color: #6649c4;
          }

          .van-dropdown-item__icon::before {
            display: none;
          }
        }
      }
    }

    .device-level {
      margin:4px 0;
      padding: 0px 16px;
      font-size: 14px;
      font-weight: normal;
      color: #939598;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /deep/ .van-dropdown-menu__bar {
        width: 55px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #d1d3d4;
        box-shadow: none;

        .van-dropdown-menu__item {
          justify-content: flex-start;
        }

        .van-dropdown-menu__title {
          font-size: 11px;
          color: #939598;
          padding-right: 12px;
        }

        .van-dropdown-menu__title--active {
          color: #939598;
        }

        .van-dropdown-menu__title::after {
          top: 42%;
          color: #d1d3d4;
          border: 4px solid #d1d3d4;
          border-color: transparent transparent #d1d3d4 #d1d3d4;
        }
      }

      /deep/ .van-dropdown-item {
        left: 16%;
        width: 18%;
        height: 30%;
        border-radius: 5px;

        .van-overlay {
          height: auto;
        }

        .van-dropdown-item__content {
          border-radius: 5px;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
          top: 1px;
          left: 6px;
          right: 5px;
          width: auto;
          max-height: 100px;
        }

        .van-cell {
          height: 25px;
          line-height: 25px;
          padding: 0 0 0 6px;
        }

        .van-cell::after {
          display: none;
        }

        .van-cell__title {
          padding-left: 3px;
          font-size: 11px;
          color: #939598;
          white-space: nowrap;
        }

        .van-dropdown-item__option--active {
          background: var(--dropDownActiveColor);
          border-radius: 5px;

          .van-cell__title {
            color: #ffffff !important;
          }
        }

        .van-dropdown-item__icon {
          color: #6649c4;
        }

        .van-dropdown-item__icon::before {
          display: none;
        }
      }
    }

    .device-type {
      text-align: center;
      font-size: 16px;
      color: #939598;
    }

    .custom-curve {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      .custom-item {
        width: 80px;
        height: 44px;
        line-height: 44px;
        border-radius: 6px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        word-break: break-all;
        line-height: 13px;
        background: var(--modeNormalBackground);
        color: var(--modeNormalTextColor);
      }

      .recommended-button-active {
        background: var(--modeActiveBackground) !important;
        color: var(--modeActiveTextColor) !important;
      }
    }

    .recommend-curve {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 0 16px;

      .recommend-item {
        width: 164px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 6px;
        font-size: 16px;
        padding: 0;
        background: var(--modeNormalBackground);
        color: var(--modeNormalTextColor);
      }

      .recommended-button-active {
        background: var(--modeActiveBackground) !important;
        color: var(--modeActiveTextColor) !important;
      }
    }

    .control {
      display: flex;
      margin-top: 16px;
      margin-bottom: 22px;
      padding: 0 16px;

      /deep/ .van-switch {
        height: 0.5em;
      }

      /deep/ .van-switch--on {
        .van-switch__node {
          background: #86a1a9;
          border: 1px solid #7b959e;
        }
      }

      /deep/ .van-switch__node {
        background: #e5e5e5;
        top: -60%;
        left: -4%;
      }

      .control-left {
        width: 100%;
        height: 170px;
        background: #f1f2f2;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 0 16px 0 10px;

        .title {
          margin-top: 18px;
          font-size: 15px;
          font-weight: bold;
          color: #6d6e71;
          display: flex;
          align-items: center;

          .preheat-switch {
            margin-left: auto;
          }
        }

        .subtitle {
          font-size: 11px;
          font-weight: 400;
          color: #939598;
          margin: 12px 0 0 0;
        }

        .preheat-item {
          width: 160px;
          height: 30px;
          border-radius: 5px;
          margin: 5px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 24px;
            height: 24px;
          }

          img:nth-child(1) {
            margin-right: auto;
            margin-left: 11px;
          }

          img:nth-child(3) {
            margin-left: auto;
            margin-right: 10px;
          }

          span {
            font-size: 11px;
            font-weight: bold;
            color: #555555;
          }
        }
      }

      .control-right {
        height: 170px;
        margin-left: 8px;
        background: #f1f2f2;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 0 12px;

        .title {
          margin-top: 18px;
          font-size: 15px;
          font-weight: bold;
          color: #555555;
          white-space: nowrap;
        }

        .nfc-switch {
          margin-top: 22px;
        }

        .msg {
          margin-top: 26px;
          font-size: 13px;
          font-weight: 400;
          color: #939598;
          line-height: 13px;
        }
      }
    }
  }

  .mask-bottom {
    position: absolute;
    bottom: 90px;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, transparent 20%, #e5e5e5 100%);
    z-index: 999;
    pointer-events: none;
  }

  .footer {
    border-top: 1px solid #d1d3d4;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    z-index: 999;
    background: #ffffff;
    display: flex;
    align-items: center;

    .button-device {
      margin-left: 28px;
      flex-wrap: wrap;
      display: flex;
      text-align: center;
      width:40px;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #939598;

      img {
        width: 30px;
        height: 36px;
        margin-bottom: 4px;
      }
      .text {
        line-height:14px;
      }
    }

    .button-upload {
      margin: 0 auto;
      width: 180px;
      height: 60px;
      background: var(--syncButtonColor);
      border-radius: 8px;
      font-size: 20px;
      font-weight: bold;
      border: none;
    }

    .button-history {
      margin-right: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #939598;

      img {
        width: 33px;
        height: 31px;
        margin-bottom: 10px;
      }
      .text {
        line-height:28px;
      }
    }
  }

  .bluetooth-popup {
    display: flex;
    flex-direction: column;

    .popup-header {
      margin: 24px 0 35px 0;
      padding: 0 25px;
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      display: flex;
      align-items: center;

      span {
        margin-left: auto;
        font-size: 21px;
        font-weight: bold;
        color: #bcbec0;
      }
    }

    .popup-content {
      margin: 0 25px 50px 25px;
      background: #ffffff;
      box-shadow: 0px 1px 10px 1px rgba(126, 133, 135, 0.6);
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .device-title {
        margin-top: 29px;
        margin-left: 34px;
        font-size: 24px;
        font-weight: bold;
        color: #6d6e71;
      }

      .device-mac {
        margin-top: 14px;
        margin-left: 34px;
        font-size: 18px;
        font-weight: normal;
        color: #939598;
      }

      .device-list {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 15px;

        .device-left {
          width: 16px;
          height: 35px;
        }

        .device-hub-list {
          width: calc(100vw - 112px);
          overflow: hidden;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }

        .device-right {
          width: 19px;
          height: 35px;
        }
      }

      .button-connect {
        width: calc(100% - 32px);
        margin: 0 16px;
        height: 45px;
        background: var(--curveColor);
        border-radius: 5px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 29px;
      }

      .no-device {
        margin: 0 auto;
        margin-top: 124px;
      }

      .no-device-msg {
        margin-top: 10px;
        margin-bottom: 185px;
        font-size: 18px;
        font-weight: normal;
        color: #bcbec0;
        text-align: center;
      }
    }
  }

  .save-popup {
    .save-header {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      padding: 20px 0;
      text-align: center;
    }

    .save-content {
      padding: 15px 23px 20px 23px;
      font-size: 18px;
      color: #231f20;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .edit-content {
      padding: 15px 23px 20px 23px;
      font-size: 18px;
      color: #231f20;
      display: flex;
      flex-direction: column;

      .edit-content-msg {
        font-size: 18px;
        color: #231f20;
      }

      .input-rename {
        margin-top: 10px;
        height: 45px;
        border-radius: 8px;
        border: 1px solid #a7a9ac;
        background: #ffffff;
      }

      .memo-content-msg {
        margin-top: 10px;
        font-size: 18px;
        color: #231f20;
      }

      .input-memo {
        margin-top: 10px;
        height: 180px;
        border-radius: 8px;
        border: 1px solid #a7a9ac;
        background: #ffffff;
      }
    }

    .save-footer {
      display: flex;
      align-items: center;
      width: 100%;

      .save-footer-cancel {
        margin-right: 5px;
        margin-top: 30px;
        width: 100%;
        color: #939598;
        background: var(--cancelButtonColor);
      }

      .save-footer-sure {
        margin-left: 5px;
        color: #ffffff;
        background: var(--themeMainColor);
        margin-top: 30px;
        width: 100%;
      }
    }
  }

  .settings-popup {
    width: 90%;

    .popup-header {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      display: flex;

      align-items: center;
      padding: 20px 20px 48px 28px;

      img {
        margin-left: auto;
        width: 28px;
        height: 28px;
      }
    }

    .popup-content {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      padding: 0 20px 0 28px;

      .title {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: normal;
        color: #a7a9ac;
      }

      .info {
        font-size: 18px;
        font-weight: normal;
        color: #808285;
        display: flex;
        align-items: center;
        margin-bottom: 33px;

        img {
          margin-left: auto;
          margin-right: 3px;
          width: 19px;
          height: 19px;
        }
      }

      .device {
        margin-bottom: 34px;

        .device-name {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .device-address {
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }

      .preheat {
        margin-bottom: 45px;

        .preheat-title {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .preheat-time {
          margin-top: 20px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }

        .preheat-voltage {
          margin-top: 17px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }

      .voltage {
        margin-bottom: 33px;

        .voltage-title {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .voltage-mode {
          margin-bottom: 24px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }
    }

    .popup-button {
      margin: 62px 25px 27px 25px;
      height: 45px;
      line-height: 45px;
      background: var(--themeMainColor);
      border-radius: 8px;
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .account-popup {
    width: 90%;

    .popup-header {
      font-size: 21px;
      font-weight: normal;
      color: #6d6e71;
      display: flex;
      align-items: center;
      padding: 20px 20px 48px 28px;

      img {
        margin-left: auto;
        width: 28px;
        height: 28px;
      }
    }

    .popup-content {
      display: flex;
      flex-direction: column;
      padding: 0 20px 0 28px;

      .title {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: normal;
        color: #a7a9ac;
      }

      .info {
        font-size: 18px;
        font-weight: normal;
        color: #808285;
        display: flex;
        align-items: center;
        margin-bottom: 33px;

        img {
          margin-left: auto;
          margin-right: 3px;
          width: 19px;
          height: 19px;
        }
      }
    }

    .popup-button {
      margin: 62px 25px 27px 25px;
      height: 45px;
      line-height: 45px;
      background: var(--themeMainColor);
      border-radius: 8px;
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
      transition: opacity 0.2s;
      -webkit-appearance: none;
    }

    .popup-button:active {
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .rename-popup {
    width: 90%;

    .popup-header {
      display: flex;
      justify-content: center;
      margin: 20px 0 40px 0;
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
    }

    .popup-content {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: normal;
      color: #231f20;
      padding: 0 17px;

      .popup-rename {
        margin-top: 10px;
        height: 45px;
        border-radius: 8px;
        border: 1px solid #a7a9ac;
        background: #ffffff;
      }

      .memo-content-msg {
        margin-top: 10px;
        font-size: 18px;
        color: #231f20;
      }

      .input-memo {
        margin-top: 10px;
        height: 180px;
        border-radius: 8px;
        border: 1px solid #a7a9ac;
        background: #ffffff;
      }
    }

    .popup-footer {
      display: flex;
      padding: 0 17px;
      margin: 35px 0 20px 0;

      button {
        width: 100%;
      }

      .footer-cancel {
        margin-right: 5px;
        background: var(--cancelButtonColor);
        border-radius: 5px 5px 5px 5px;
        color: #939598;
      }

      .footer-confirm {
        margin-left: 5px;
        background: var(--themeMainColor);
      }
    }

    /deep/ .van-popup__close-icon {
      font-size: 32px;
    }
  }
}

/deep/ .van-dropdown-item__option {
  img {
    width: 22px;
    height: 22px;
  }
}

.drop-item-level {
  width: 20px;
  height: 20px;
  border: 1px #999 solid;
  border-radius: 50%;
}

.drop-item-level__wrap {
  width: 100%;
  padding: 5px 0;
}

.drop-item-level__content {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border: 1px #999 solid;
  border-radius: 50%;
}
</style>
